<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Create a New KYC settings</h1>
    </div>
    <CreateKycForm />
  </section>
</template>
      
      <script lang="ts">
import { defineComponent } from "vue";
import CreateKycForm from "@/views/settings/constituent/CreateKycForm.vue";

export default defineComponent({
  name: "CreateKyc",
  components: {
    CreateKycForm,
  },
  setup() {
    return {};
  },
});
</script>
      
      <style scoped></style>
      