
import { defineComponent } from "vue";
import CreateKycForm from "@/views/settings/constituent/CreateKycForm.vue";

export default defineComponent({
  name: "CreateKyc",
  components: {
    CreateKycForm,
  },
  setup() {
    return {};
  },
});
