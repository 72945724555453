<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="level" class="form-label">Level</label>
                <InputWrapper :errors="formError.level">
                  <input
                    type="number"
                    v-model="formData.level"
                    class="form-control"
                    id="level"
                    required
                  />
                </InputWrapper>
              </div>
            </div>
            <div class="mb-3">
              <div>
                <label
                  for="cummulativeWithdrawerLimit"
                  class="form-label"
                >Cummulative Withdrawal Limit</label>
                <InputWrapper :errors="formError.cummulativeWithdrawerLimit">
                  <input
                    type="number"
                    v-model="formData.cummulativeWithdrawerLimit"
                    class="form-control"
                    id="cummulativeWithdrawerLimit"
                    required
                  />
                </InputWrapper>
              </div>
            </div>
            <div class="mb-3">
              <div>
                <label for="dailyWithdrawerLimit" class="form-label">Daily Withdrawal Limit</label>
                <InputWrapper :errors="formError.dailyWithdrawerLimit">
                  <input
                    type="number"
                    v-model="formData.dailyWithdrawerLimit"
                    class="form-control"
                    id="withdrawerLimit"
                    required
                  />
                </InputWrapper>
              </div>
            </div>
            <div class="mb-3">
              <div>
                <label for="description" class="form-label">Description</label>
                <InputWrapper :errors="formError.description">
                  <textarea
                    rows="3"
                    type="number"
                    v-model="formData.description"
                    placeholder="Add description"
                    class="form-control"
                    id="description"
                    required
                  />
                </InputWrapper>
              </div>
            </div>
            <div class="mb-3">
              <label for="requirements" class="form-label">Requirements</label>
              <div class="d-flex">
                <InputWrapper :errors="formError.requirements">
                  <textarea
                    type="text"
                    v-model="newRequirement"
                    placeholder="Add requirement"
                    class="form-control"
                    id="requirements"
                    @keydown.enter.prevent="addRequirement"
                  />
                </InputWrapper>
                <div class="text-center">
                  <button
                    @click="addRequirement"
                    :disabled="newRequirement == ''"
                    type="button"
                    class="btn btn-info"
                  >Add</button>
                </div>
              </div>
            </div>

            <div class="text-center">
              <button
                :disabled="formData.requirements.length == 0"
                type="submit"
                class="btn btn-dark"
              >Create KYC setting</button>
            </div>
          </form>
          <div class="row row-cols-1 col-md-2 g-0 w-100 gap-md-5 my-3">
            <div
              v-if="formData.requirements"
              class="overflow-auto d-flex flex-wrap"
              style="max-height: 24rem"
            >
              <div
                v-for="(req, index) in formData.requirements"
                :key="req"
                class="d-inline-flex align-items-center bg-light rounded-md p-3 py-1 m-3 ml-0"
              >
                {{ req }}
                <button @click="removeRequirement(index)" class="btn btn-link text-danger">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateKycForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const newRequirement = ref("");

    interface FormData {
      level: string;
      cummulativeWithdrawerLimit: number | null;
      dailyWithdrawerLimit: number | null;
      description: string | null;
      requirements: string[];
    }
    const formData = ref<FormData>({
      level: "",
      cummulativeWithdrawerLimit: null,
      dailyWithdrawerLimit: null,
      description: null,
      requirements: [],
    });
    const formError = ref({});
    const loading = ref(false);

    const addRequirement = (): void => {
      if (
        newRequirement.value &&
        !formData.value.requirements.includes(newRequirement.value)
      ) {
        formData.value.requirements.push(newRequirement.value);
        newRequirement.value = "";
      }
    };
    const removeRequirement = (index: number) => {
      formData.value.requirements.splice(index, 1);
    };

    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/kyc`, formData.value);
        ShowSuccess("Kyc settings created Successfully", () => {
          formData.value = {
            level: "",
            cummulativeWithdrawerLimit: null,
            dailyWithdrawerLimit: null,
            description: null,
            requirements: [],
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create kyc: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      addRequirement,
      removeRequirement,
      newRequirement,
      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>
